import React, {useState, useEffect} from "react";
import { Container } from "react-grid-system";
import {Spinner} from "../components/spinner/Spinner";
import SurveyApi from "../utils/SurveyApi";
import {Survey} from "../components/survey/Survey";
import {SurveyData} from "../types";
const Home: React.FC<any> = (props) => {
  const {
    match: { params }
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [survey, setSurvey] = useState<SurveyData | null>(null);
  useEffect(() => {
    const surveyApi = new SurveyApi(params.token);
    surveyApi.getInfo().then(res => {
      setSurvey(res.survey as SurveyData);
    }).catch(err => {
      console.log(err.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [params.token]);
  return <>
    {isLoading && <Spinner />}
    {!isLoading && !!survey && <Survey survey={survey} token={params.token} />}
  </>;
};

export default Home;
