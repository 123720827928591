import React from "react";
import s from "./Survey.module.css";
import { SurveyData } from "../../types";
import { Container } from "react-grid-system";
import classnames from "classnames";
type Props = {
  survey: SurveyData;
  token: string;
};

const scores: number[] = [];
for (let i = 0; i <= 10; i++) {
  scores.push(i);
}
export const Survey: React.FC<Props> = ({ survey, token }) => {
  return (
    <>
      <div
        className={s.line}
        style={{ backgroundColor: survey.template.color }}
      />
      <Container>
        <div className={s.surveyContainer}>
          {survey.template.logo && <div className={s.logoContainer}>
            <img
              src={survey.template.logo?.content}
              alt={""}
              style={{
                width: survey.template.logo?.width,
                height: survey.template.logo?.height
              }}
            />
          </div>}
          <div className={s.textContainer}>{survey.template.paragraph}</div>
          <div className={s.questionAndScores}>
            <div
              className={classnames({
                [s.textContainer]: true,
                [s.question]: true
              })}
            >
              {survey.template.question}
            </div>
          </div>
          <div className={s.scoresContainer}>
            {scores.map(score => (
              <a
                key={score}
                href={`${survey.answersWeb}/?campaignId=${survey.campaignId}&token=${token}&score=${score}`}
                title={"Einkunn: " + score}
              >
                <span className={s.score}>{score}</span>
              </a>
            ))}
          </div>
          <div
            className={s.textContainer}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            {survey.template.footer}
          </div>
        </div>
      </Container>
      <div
        className={s.line}
        style={{ backgroundColor: survey.template.color }}
      />
      <div className={s.promoted}>
        Þessi könnun var send með{" "}
        <a href="https://promoted.is" target="_blank">
          Promoted
        </a>
      </div>
    </>
  );
};
