export const get = async (url: string, headers: any) => {
  const API = process.env.REACT_APP_SURVEY_API;
  headers["Content-Type"] = "application/json";
  headers["Accept"] = "application/json";
  const data = await fetch(`${API}${url}`, { method: "GET", headers });
  const res = await data.json();
  if (data.status >= 400) {
    throw new Error(res.message || "Óvænt villa kom upp");
  }
  return res;
};
